import React from 'react';

import 'react-awesome-slider/src/core/styles.scss';
import 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import 'react-awesome-slider/src/styled/fall-animation/fall-animation.scss';
import 'react-awesome-slider/src/styled/cube-animation/cube-animation.scss';

// import 'react-awesome-slider/dist/custom-animations/cube-animation.css';

export default function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />;
}
